import { Component } from '@angular/core';

/**
 * @title Basic slide-toggles
 */
@Component({
  selector: 'reports-container',
  templateUrl: 'reports.container.html'

})
export class ReportsContainer {

}
