import { Component } from '@angular/core';
import { AdalService } from 'adal-angular4';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  mainUrl = window.location.origin;
  private adalConfiguration = {
    tenant: '1e699703-f2ca-4bf1-9057-eb673861d4d6',
    clientId: '1103419b-5685-4339-84d6-86ba8699259c',
    redirectUri: this.mainUrl + '/auth-callback',
    postLogoutRedirectUri: this.mainUrl + '/logout'
  }

  constructor(private adal: AdalService) {
    this.adal.init(this.adalConfiguration);
  }

}
