import { Component,Input } from '@angular/core';
import { DataFlowService } from '../../services/data-flow.service';
import { CommonPresenter } from '../../services/common.presenter';
import { Subscription } from 'rxjs';
@Component({
  selector: 'overview-component',
  templateUrl: './overview.component.html',
})
export class OverviewComponent {
  constructor(public data: DataFlowService, public presenter: CommonPresenter) { }
  ticketInfoSubscr: Subscription
  ticketData;
  wholeTicket;
  returnToFixed(number) {
    if (number) {
      return Number(number).toFixed(2);
    }


  }
  arr(array) {
    
    return array.join(', ')
  }
  ngOnInit() {
    this.ticketInfoSubscr = this.data.castTicketInfo.subscribe(result => {
      if (result) {
        this.ticketData = result.nonRoutineItem;
        this.wholeTicket=result
       
      }
     
    })

  }
  ngOnDestroy() {
    this.ticketInfoSubscr.unsubscribe();
  

  }
}
