import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription, from } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CommonPresenter {

  constructor(private route: ActivatedRoute) { }
  id: number;
 
  getItemId() {
    this.route.params.subscribe(params => this.id = params.id);
    return this.id;
  }

  convertDate(dateString: string) {

    let date = new Date(dateString);
    let day = this.format_two_digits(date.getDate());
    let month = this.format_two_digits(date.getMonth() + 1);
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let transformedDate = day + '.' + month + '.' + year + ' ' + this.format_two_digits(hours) + ':' + this.format_two_digits(minutes)
    return transformedDate;
  }
  format_two_digits(n) {
    return n < 10 ? '0' + n : n;
  }
  convertMmToInches(mm) {
    return mm / 25.4
  }

  //get only changed data from forms
  submitChangedData(data: FormGroup) {
    const dirtyValues = {};
    Object.keys(data.controls).forEach(c => {
      const currentControl = data.get(c);

      if (currentControl.dirty) {
        dirtyValues[c] = currentControl.value;
      }
    });

    return dirtyValues;
  }
}
