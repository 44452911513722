import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Filters } from '../models';

@Injectable()
export class FiltersService {
  statusOneToFilter = null;
  statusTwoToFilter = null;
  reasonToFilter = null;
  priorityToFilter = null;
  aircraftToFilter = null
  assignmentToFilter = null;


  filter(message, filter: number) {
    if (filter == Filters.STATUS_ONE) {
      this.statusOneToFilter = message;
    }
    if (filter == Filters.STATUS_TWO) {
      this.statusTwoToFilter = message;
    }
    if (filter == Filters.REASON) {
      this.reasonToFilter = message;
    }
    if (filter == Filters.PRIORITY) {
      this.priorityToFilter = message.name

    }
    if (filter == Filters.AIRCRAFT) {
      this.aircraftToFilter = message
    }
    if (filter == Filters.ASSIGNMENT) {
      this.assignmentToFilter = message.name
    }
  }

}
