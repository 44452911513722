import { Component, OnInit } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { Router } from '@angular/router';
@Component({
  selector: 'app-auth-callback',
  template: '<p>authentication works.....</p>',
})
export class AuthCallbackComponent implements OnInit {
 
  constructor(private adal: AdalService, private router: Router) { }
 
  ngOnInit() {
   this.adal.handleWindowCallback();
    if (!this.adal.userInfo.authenticated) {
      this.router.navigate(['login']);
    } else {
      this.router.navigate(['home']);
    }
//you can do redirect here to another page after login
  }
 
}
