import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {TicketInfo,User } from '../models';

@Injectable()
export class DataFlowService {
  private ticket = new BehaviorSubject<TicketInfo>(null);
  castTicketInfo = this.ticket.asObservable();

  updateTicketInfo(ticket) {
    this.ticket.next(ticket)
  }

  private currentUser = new BehaviorSubject<User>(null);
  castCurrentUser = this.currentUser.asObservable();

  updateCurrentUser(user) {
    this.currentUser.next(user)
  }

}
