import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../models'
@Injectable({ providedIn: 'root' })

export class apiService {
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  headerOptions = { headers: this.httpHeaders }

  private tickets = '/api/Tickets';
  private statuses = '/api/TicketStatuses';
  private users = '/api/Users';
  private currentUser = '/api/Users/GetByEmail';
  private approvalDocuments = 'api/ApprovalDocuments';
  private category = '/api/EOCategories/';

  constructor(private http: HttpClient) { }
  getUsers(): Observable<models.User[]> {
    return this.http.get<models.User[]>(this.users, this.headerOptions);
  }
  getApprovalDocuments() {
    return this.http.get<any[]>(this.approvalDocuments, this.headerOptions);
  }
    getCategory() {
      return this.http.get<any[]>(this.category, this.headerOptions);
    }
  getTickets(): Observable<models.Ticket[]> {
    return this.http.get<models.Ticket[]>(this.tickets, this.headerOptions);
  }
  getStatuses(): Observable<models.TicketStatus[]> {
    return this.http.get<models.TicketStatus[]>(this.statuses, this.headerOptions);
  }
  getTicketInfo(id:number) {
    const url = `${this.tickets}/${id}`;
    return this.http.get<models.TicketInfo[]>(url, this.headerOptions);

  }
  getCurrentUser(email: string): Observable<models.User[]> {
    const url = `${this.currentUser}/${email}`;
    return this.http.get<models.User[]>(url, this.headerOptions);

  }


}
