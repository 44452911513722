import {Component,Output,Input,EventEmitter} from '@angular/core';

/**
 * @title Basic slide-toggles
 */
@Component({
  selector: 'select-component',
  templateUrl: 'select.component.html'

})
export class SelectComponent{
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() statusOneEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() statusTwoEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() reasonEmitter: EventEmitter<any> = new EventEmitter<any>();
  private _options;
  statusOne=[];
  statusTwo=[];
  statusReason=[];

  //aircraft

  @Input() set options(val) {
    this._options = val;
    if (this._options) {
      this._options.forEach(item => {
        if (this.statusOne.indexOf(item.statusOne) < 0) {
          this.statusOne.push(item.statusOne)
        }
        if (this.statusTwo.indexOf(item.statusTwo) < 0) {
          this.statusTwo.push(item.statusTwo)
        }
        if (this.statusReason.indexOf(item.reason) < 0) {
          this.statusReason.push(item.reason)
        }
      
      })
    }
 
  
    
  }
  get options() {
    return this._options
  }
  @Input() dropdownType;
  @Input() selectedOption;

  public objectComparisonFunction = function (option, value): boolean {
    if (value) {
      return option.id == value.id;
    }
  }

  returnNonRepeating() {

  }
  testOptions = [
    { value: 'test-1', viewValue: 'test 1' },
    { value: 'test-2', viewValue: 'test 2' },
    { value: 'test-3', viewValue: 'test 3' }
  ];
}
