import { Component } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { AuthGuardService } from '../../app/services/auth-guard.service';
import { Subscription } from 'rxjs';
import { DataFlowService } from '../services/data-flow.service';
import { apiService } from '../services/api.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  show: boolean = false;
  username: string;
  email: string;
  user;
  azureUserSubscription: Subscription;
  currentUserSubscription: Subscription;
  showDropdown() {
    this.show = !this.show;
  }
  constructor(private adal: AdalService, private guard: AuthGuardService, private data: DataFlowService, private api: apiService) { }
  logOut() {
    this.adal.logOut();
  }
  ngOnInit() {
    if (!this.adal.userInfo.authenticated) {
      this.guard.updateUser('');
    }
    this.azureUserSubscription = this.guard.cast.subscribe(result => {
      this.user = result
      if (this.user) {
        this.username = this.adal.userInfo.userName.split('@')[0]
        this.email = this.adal.userInfo.userName
        this.currentUserSubscription = this.api.getCurrentUser(this.email).subscribe(result => {
          if (result && result[0]) {
            this.data.updateCurrentUser(result[0])
          }
        })
   
      }
    })
   
  }
  ngOnDestroy() {
    this.azureUserSubscription.unsubscribe();
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
    this.data.updateCurrentUser(null)
  }
}
