import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {AppMaterialModule } from './material.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './header/nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { HomeContainer } from '../app/home/home.container';
import { NRIComponent } from '../app/nonRoutineItem/nri.component';
import { NRIContainer } from '../app/nonRoutineItem/nri.container';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { SlideToggleComponent } from '../app/common/slide-toggle/slide-toggle.component';
import { SelectComponent } from '../app/common/dropdown/select.component';
import { HelpComponent } from '../app/help/help.component';
import { ReportsComponent } from '../app/reports/reports.component';
import { ReportsContainer } from '../app/reports/reports.container';
import { OverviewComponent } from '../app/nonRoutineItem/overview/overview.component';
import { StepsComponent } from '../app/nonRoutineItem/steps/steps.component';
import { WhatWhereComponent } from '../app/nonRoutineItem/what-where/what-where.component';
import { apiService } from '../app/services/api.service';
import { CommonPresenter } from '../app/services/common.presenter';
import { DataFlowService } from '../app/services/data-flow.service';
import { DialogComponent } from '../app/common/modal/dialog.component';
import { PeoplePickerComponent } from '../app/common/peoplePicker/people-picker.component';
import { QuillModule } from 'ngx-quill';
import { PopoverComponent } from '../app/common/popover/popover-common.component';
import { HistoryComponent } from '../app/nonRoutineItem/history/history.component';

import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout';
import { AuthCallbackComponent } from './auth/authcallback/authcallback';
import { AuthGuardService as AdalGuard } from './services/auth-guard.service';
import { AdalService, AdalInterceptor } from 'adal-angular4';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerComponent } from '../app/common/spinner/spinner';
import { FiltersService} from '../app/services/filters.service';
@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    HomeContainer,
    HeaderComponent,
    SlideToggleComponent,
    SelectComponent,
    NRIContainer,
    SpinnerComponent,
    NRIComponent,
    HelpComponent,
    AuthCallbackComponent,
    ReportsComponent,
    ReportsContainer,
    DialogComponent,
    OverviewComponent,
    StepsComponent,
    WhatWhereComponent,
    PeoplePickerComponent,
    PopoverComponent,
    HistoryComponent,
    LogoutComponent,
    LoginComponent
  ],

  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
 
    AppMaterialModule,
    ReactiveFormsModule,
    NgbModule,
    QuillModule.forRoot(),
    RouterModule.forRoot([
      { path: '', redirectTo: 'login', pathMatch: 'full', },
      { path: 'login', component: LoginComponent },
      { path: 'logout', component: LogoutComponent },
      { path: 'home', component: HomeContainer, pathMatch: 'full', canActivate: [AdalGuard] },
      { path: 'ticket/:id', component: NRIContainer, canActivate: [AdalGuard] },
      { path: 'help', component: HelpComponent, canActivate: [AdalGuard]  },
      { path: 'reports', component: ReportsContainer, canActivate: [AdalGuard] },
      { path: 'auth-callback', component: AuthCallbackComponent }
    ]),
    BrowserAnimationsModule
  ],
  providers: [apiService,CommonPresenter, DataFlowService, AdalGuard, AdalService, { provide: HTTP_INTERCEPTORS, useClass: AdalInterceptor, multi: true }],
  exports: [PopoverComponent, RouterModule],
  entryComponents: [DialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
