import { Component, Input } from '@angular/core';
import {CommonPresenter } from '../services/common.presenter';
import { FormGroup } from '@angular/forms';

import { Filters} from '../models';
import { FiltersService } from '../services/filters.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  providers: [FiltersService]
})

export class HomeComponent {

  public _tickets;
  private _allTickets;
  filtersArr=[];
  @Input() statuses;


  @Input() set tickets(val: string) {
    this._tickets = val;
    this._allTickets = val;


    if (this._tickets) {
      this.showSpinner = false;
    }
  }
  get actionType(): string {
    return this._tickets
  }

  showSpinner: boolean = true;;
  checked: boolean = true;
  label: string = 'yes';
  priority = [
    { name: 'Normal',id:1 },
    { name: 'Critical', id: 2}
   
  ];



 bay = [
    { name: 1,id:1 },
    { name: 2, id: 2},
   { name: 3, id: 3 },
   { name: 4, id: 4 },
   { name: 5, id: 5 },
   { name: 6, id: 6 },
   { name: 7, id: 7 },
   { name: 8, id: 8 },
  ];



  constructor(private presenter: CommonPresenter, private filterService: FiltersService) { }
  changeToggle(e) {
    if (e.checked) {
      this.label = 'yes'

    } else {
      this.label = 'no'
    }

  }
  assignTicket(message, row) {
    row.owner = message;

  }

  
  filterTickets() {
    this._tickets = this._allTickets;
    if (this.filterService.statusOneToFilter) {
      this._tickets = this._tickets.filter(option =>
        option.status.statusOne.toLowerCase().includes(this.filterService.statusOneToFilter.toLowerCase())
      );
    }

    if (this.filterService.statusOneToFilter && this.filterService.statusTwoToFilter ) {
        this._tickets = this._tickets.filter(option => 
          option.status.statusOne.toLowerCase().includes(this.filterService.statusOneToFilter.toLowerCase()) &&
          option.status.statusTwo.toLowerCase().includes(this.filterService.statusTwoToFilter.toLowerCase())

        );
      }
    if (this.filterService.statusOneToFilter && this.filterService.statusTwoToFilter && this.filterService.reasonToFilter) {
        this._tickets = this._tickets.filter(option => 
          option.status.statusOne.toLowerCase().includes(this.filterService.statusOneToFilter.toLowerCase()) &&
          option.status.statusTwo.toLowerCase().includes(this.filterService.statusTwoToFilter.toLowerCase()) &&
          option.status.reason.toLowerCase().includes(this.filterService.reasonToFilter.toLowerCase())
        );
      }
    if (this.filterService.priorityToFilter) {
        
        this._tickets = this._tickets.filter(option => 
        
          option.priority.toLowerCase().includes(this.filterService.priorityToFilter.toLowerCase())
       
        );
       
      
      }
    if (this.filterService.assignmentToFilter && this.filterService.assignmentToFilter!="") {
        this._tickets = this._tickets.filter(option => {
          if (option.owner) {
            return option.owner.name.toLowerCase().includes(this.filterService.assignmentToFilter.toLowerCase())
          }
        });
      }
    if (this.filterService.aircraftToFilter) {
     
        this._tickets = this._tickets.filter(option => 
          option.layover.toLowerCase().includes(this.filterService.aircraftToFilter.toLowerCase())
          );

     
    }

      console.log(this._tickets)
   

  

  
  }
  clearFilters() { }
}
