import { Component,Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'popover-component',
  templateUrl: './popover-common.component.html'
})
export class PopoverComponent {
  private _category;
  private _allCategories;

  @Input() set category(val: string) {
    this._category = val;
    this._allCategories = val;
   
  }
  get category(): string {
    return this._category
  }


  @Input() labelTxt: string;
  @Input() index: number;
  @Input() popoverType: string;

  @Input() classification;
  @Input() approvalDocument;
 // @Input() category;

  @Input() users;
 

  @Output() confirm: EventEmitter<number> = new EventEmitter<number>();
  @Output() assignTicket = new EventEmitter<any>();
  @Output() generate = new EventEmitter<any>();
  user;
 
  userSelected(message) {
    this.user = message
  }

  getLists() {
    if (!this.classification || !this.approvalDocument || !this.category) {
      this.generate.emit(true)
    }
  }
  filterCategory(message) {
    if (message.value.name == 'RDAS') {
      this._category = this._allCategories.filter(item => item.approvalDocumentId == message.value.id)
    }
    if (message.value.name == 'TA') {
      this._category = this._allCategories.filter(item => item.approvalDocumentId == message.value.id)
    }
    if (message.value.name != 'TA' && message.value.name != 'RDAS') {
      this._category = this._allCategories;
    }
  }

}
