import { Component, EventEmitter, Output, Input, ViewChild} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StepsComponent } from './steps/steps.component';
import { CommonPresenter } from '../services/common.presenter';
import {FiltersService } from '../services/filters.service';
@Component({
  selector: 'nri-component',
  templateUrl: './nri.component.html',
  providers: [FiltersService]
})
export class NRIComponent {
 

  @ViewChild(StepsComponent, { static: false }) stepsComponent: StepsComponent;
  @Input() statuses;
  @Input() users;
  @Input() mainTicketInfo;



  overviewData: FormGroup;
  defectData: FormGroup;

  constructor(private fb: FormBuilder, private commonPresenter: CommonPresenter, private filterService: FiltersService) {
  
  }

  showComment: boolean = false;
  show: boolean = true;
  selectionChange(message) {
    this.showComment = true;
   
  }
  onTabChange(e) {
    //if (e.activeId == 'orders' && this.stepsComponent.form.dirty) {
    //  this.stepsComponent.form.ngSubmit.emit();
 
    //}
  
  }
  submitSteps(data) {
   
  }

  assignTicket(message, item) {
    item.owner = message;
  }

}
