import { Component } from '@angular/core';
import { apiService } from '../services/api.service';
import { DataFlowService } from '../services/data-flow.service';
import { Observable, Subscription } from 'rxjs';
import { TicketInfo, TicketStatus,User} from '../models';
import { CommonPresenter } from '../services/common.presenter';
import { Router } from '@angular/router';
@Component({
  selector: 'nri-container',
  templateUrl: './nri.container.html',
  providers: [CommonPresenter]
})
export class NRIContainer {
  constructor(private api: apiService, private presenter: CommonPresenter, private dataFlow: DataFlowService) { }
  ticketInfoAPI: Subscription
  ticketInfopPass: Subscription
  statuses$: Observable<TicketStatus[]> = this.api.getStatuses();
  users$: Observable<User[]> = this.api.getUsers();
  mainTicketInfo;
  showSpinner: boolean = true;
  ngOnInit() {
    this.ticketInfoAPI = this.api.getTicketInfo(this.presenter.getItemId()).subscribe(result => {
      if (result && result[0]) {
        this.mainTicketInfo = result[0];
        this.showSpinner = false;
        this.dataFlow.updateTicketInfo(result[0])
       
      }
    })

  }
  ngOnDestroy() {
    this.ticketInfoAPI.unsubscribe();
  }
}

