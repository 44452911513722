import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { Subscription, Observable } from 'rxjs';


import { DialogComponent} from '../../common/modal/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { apiService } from '../../services/api.service';
import { FormGroup, FormControl, FormArray, FormBuilder, NgForm } from '@angular/forms';


@Component({
  selector: 'steps-component',
  templateUrl: './steps.component.html',
})
export class StepsComponent {
  @ViewChild('form', { static: false }) form: NgForm;



  @Output() emitCancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submit: EventEmitter<FormArray> = new EventEmitter<FormArray>();

  viewOrder() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '80%'
    });
  }
  classification = [{
    id: 1,
    name: 'TBD'
  },
    {
      id: 2,
      name: 'Minor'
    },
    {
      id: 3,
      name: 'Major'
    }]
  constructor(private fb: FormBuilder, private api: apiService, public dialog: MatDialog) {
  
  }


 

  addNewEO() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    
    });
  }


  ngOnInit() {
   

  }
 
 
}


