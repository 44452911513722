
export interface TicketInfo {
  id:number,
  cras:string,
  created:string,
  createdBy:string,
  modified:string,
  modifiedBy:string,
  owner:any,
  ownerId?:number,
  priority:string,
  status:any,
  statusId?:number,
  nonRoutineItem?:any,
  nonRoutineItemId: number,
  defects?: Defect[]
}

export interface Ticket {
  Id: number,
  CRAAS: string,
  Created: Date,
  CreatedBy: string,
  Modified: Date,
  ModifiedBy: string,
  Owner: User,
  Priority: string,
  Status: TicketStatus
}

//export interface User {
//  Id: number,
//  Name: string,
//  Bay: string,
//  Email: string
//}

export interface TicketStatus {
  Id: number,
  StatusOne: string,
  StatusTwo: string,
  Reason: string
}

export interface Aircraft {
  Id: number,
  SerialNo: string,
  Model: string,
  Type: string
  Age: string,
  FlightCyclesTotal: number,
  FlightHoursTotal: number
}

export interface Operator {
  Id: number,
  Name: string
}
//Display at NRI Overview
export interface NonRoutineItem {
  id?: number,
  name?: string,
  leadingWorkStation?: WorkStation,
  involvedWorkStation?: InvolvedWorkStation[],
  originatingJobCard?: string,
  originatingWorkPack?: string,
  createdBy?: string,
  createdAt?: string,
  modifiedBy?: string,
  modifiedAt?: string,
  Aircraft: Aircraft,
  Operator: Operator,
  DescriptionText?: string
}

//Involved workstations
export interface InvolvedWorkStation {
    NonRoutineItemId: number,
    WorkStationId: number
}

//Workstations
export interface WorkStation {
    id: number,
    name: string
}

//Users
export interface User {
    Id: number,
    Name: string,
    UserWorkStation: WorkStation[],
    metricSystem?: boolean,
    IsLHTEmployee?: boolean,
    Availability?: boolean,
    Bay: string,
    SAPNumber: string
}

//ATA Chapters
export interface AtaChapter {
    id: number,
    chapter?: string,
    section?: string,
    subsection: string,
    designation: string,
    isFloor?: boolean,
    sketch: Sketch,
    fromLimit?: number,
    toLimit?: number,
    isDefault?: boolean
}

//Structural members
export interface StructuralMember {
    id: number,
    name: string
}

//Sketches
export interface Sketch {
    id: number,
    image: string,
    name: string,
    description: string,
    firstMember: StructuralMember
    secondMember: StructuralMember,
    firstPosition: PositionType,
    secondPosition: PositionType
}

//Position types
export interface PositionType {
    id: number,
    name: string,
    firstValue: string,
    secondValue: string
}

//Defect dimensions
export interface DefectDimension {
    defectId: number,
    dimensionId: number,
    value: number
}

//Edges
export interface Edge {
    id: number,
    name: string
}

//Display defect information
export interface Defect {
    id?: number,
    nonRoutineItemId?: number,
    mainType?: string,
    part?: Part,
    ataChapter?: AtaChapter,
    sketch?: Sketch,
    //alternatives?: [],
    defectType?: DefectType,
    defectSubType?: DefectSubType,
    fromMemberFirst?: number,
    toMemberFirst?: number,
    valueFirst?: number,
    closestFirst?: string,
    secondMemberType?: string,
    fromMemberSecond?: number,
    toMemberSecond?: number,
    valueSecond?: number,
    closestSecond?: string,
    quantity?: number,
    internal?: string,
    firstPosition?: string,
    secondPosition?: string,
    defectDimensions?: DefectDimension[],
    fromExtra?: string,
    fromExtraValue?: number,
    fromEdge?: Edge,
    toEdge?: Edge
}

//Defect subtypes
export interface DefectSubType {
    Id: number,
    DefectType: DefectType,
    DefectTypeId?: number,
    SubType: string
}

//Diplay when part is selected
export interface Part {
    id: number,
    fin: string,
    name: string,
    number: string,
    manufacturer: string,
    circuit: string,
    uc: number,
    ataChapter: string,
    ataSection: string,
    ataTitle: string
}

//Defect types
export interface DefectType {
    id: number,
    name: string,
    IsAVI: boolean,
    IsSYS: boolean,
    IsSTR: boolean,
    firstDimension: [],
    secondDimension: [],
    thirdDimenstion: [],
    firstDimensionId: number,
    secondDimensionId: number,
    thirdDimenstionId: number,
}

export interface FileAttachment {
  id?:number,
  name: string,
  file:File
}
export enum Filters {
  STATUS_ONE = 1,
  STATUS_TWO = 2,
  REASON = 3,
  AIRCRAFT = 4,
  PRIORITY = 5,
  ASSIGNMENT = 6
}

