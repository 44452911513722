import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable()
export class AuthGuardService implements CanActivate {
  private user = new BehaviorSubject<string>('');
  cast = this.user.asObservable();

  updateUser(loggedUser) {
    this.user.next(loggedUser)
  }

  constructor(public auth: AdalService, public router: Router) {}

  canActivate(): boolean {
    if (!this.auth.userInfo.authenticated) {
      this.router.navigate(['login']);

      return false;
    }
    this.updateUser(this.auth.userInfo)
    return true;
  }

}
