import { Component } from '@angular/core';
import { apiService } from '../services/api.service';
import { Observable } from 'rxjs';
import { Ticket, TicketStatus, User } from '../models';
import {DataFlowService } from '../services/data-flow.service';

@Component({
  selector: 'app-home-container',
  templateUrl: './home.container.html',
})
export class HomeContainer {
  constructor(private api: apiService, private data: DataFlowService) { }
  tickets$: Observable<Ticket[]> = this.api.getTickets();
  statuses$: Observable<TicketStatus[]> = this.api.getStatuses();


  resetFlow() {
    this.data.updateTicketInfo(null)
  }
  ngOnInit() {

  }
  ngOnDestroy() {

  }
}
