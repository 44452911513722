import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { apiService } from '../../services/api.service';
import { FormGroup, FormControl, FormArray, FormBuilder, NgForm } from '@angular/forms';
import { Observable} from 'rxjs'
import Quill from 'quill';

import ImageResize from 'quill-image-resize-module';
//const parchment = Quill.import('parchment')
//const block = parchment.query('block')
//block.tagName = 'DIV'
Quill.register('modules/imageResize', ImageResize);
//Quill.register(block, true)

/**
 * @title Basic slide-toggles
 */
@Component({
  selector: 'dialog-component',
  templateUrl: 'dialog.component.html',

})
export class DialogComponent {
  public referenceList = [];
  

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  saveOrder() {
    this.EOData.controls['references'].setValue(this.referenceList)
    console.log(this.EOData)
  }
  classification = [{ id: "TBD", name: "TBD" }, { id: "Minor", name: "Minor" }, { id: "Major", name: "Major" }]
  private toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons


    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    ['link', 'image'],


    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
  ];

  public quillModules = {
    imageResize: {},
    toolbar: this.toolbarOptions
    
  };
  
  EOData: FormGroup;
  

  constructor(public dialogRef: MatDialogRef<DialogComponent>, private fb: FormBuilder, private api: apiService) {

    this.EOData = this.fb.group({
      steps: this.fb.array([]),
      id: new FormControl(),
      totalEngHours: new FormControl,
      chargable: new FormControl,
      references: new FormControl
    });
  
  }

  editMode: boolean = false;
  labelEO: string = "No";
  labelCharge: string = "No";
  approvalDocument$: Observable<any[]>;
  category$: Observable<any[]>;

  uploadFile(event, fgItem: FormControl) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        let reader = new FileReader();
        reader.onload = (result: any) => {
          let fileArray= fgItem.value
          fileArray.push({ name: event.target.files[0].name, data: result.target.result })
         
          fgItem.setValue(fileArray)
          this.referenceList.push({ name: event.target.files[0].name })
          console.log(fileArray)
          
        }
        reader.readAsArrayBuffer(event.target.files[i])
      }
  
    }
   
  }




  get stepsListArray() {
    return <FormArray>this.EOData.get('steps')
  }
  stepsFields() {
    return this.fb.group({
      id: new FormControl(),
      index: new FormControl(),
      editorContent: new FormControl(),
      editorMode: new FormControl(true),
      includeEO: new FormControl(),
      attachments: new FormControl([]),
      engHoursPerStep:new FormControl()
    })
  }

  changeToggleCharge(e) {
    if (e.checked) {
      this.labelCharge = 'Yes'
      this.EOData.controls['chargable'].setValue(true)

    } else {
      this.labelCharge = 'No'
      this.EOData.controls['chargable'].setValue(false)
    }
  }
  changeToggleEO(e,fgItem:FormControl) {
    if (e.checked) {
      this.labelEO = 'Yes';
      fgItem.setValue(true)

    } else {
      this.labelEO = 'No'
      fgItem.setValue(false)
    }

    

  }
  getLists(message) {
    if (message) {
      this.approvalDocument$ = this.api.getApprovalDocuments();
      this.category$ = this.api.getCategory();
    }
  }
  test(item) {
    console.log(item)
  }
  editStep(fgroup: FormGroup) {
    fgroup.controls['editorMode'].setValue(!fgroup.controls['editorMode'].value)
    //TODO edit/save

  }
  enableEdit(fgroup: FormGroup) {
    fgroup.controls['editorMode'].setValue(!fgroup.controls['editorMode'].value)
  }

  cancelEditStep(fgroup: FormGroup) {
    fgroup.controls['editorMode'].setValue(!fgroup.controls['editorMode'].value)

  }
  addStep(e) {
    e.preventDefault();
    this.stepsListArray.push(this.stepsFields())

  }
  deleteStep(i) {

    this.stepsListArray.removeAt(i)
  }
  includeInEO(message, fgroup: FormGroup) {

    fgroup.controls['includeEO'].setValue(message)
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    //Quill.clipboard.addMatcher('B', function (node, delta) {
    //  return delta.compose(new Delta().retain(delta.length(), { bold: true }));
    //});
  }
}
