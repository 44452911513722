import { Component, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { apiService } from '../../services/api.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'people-picker-component',
  templateUrl: 'people-picker.component.html',
})
export class PeoplePickerComponent {
  @Input() users;
  @Output() userSelected = new EventEmitter<any>();
  peoplePickerControl = new FormControl();
  filterSubscr: Subscription;
  usersSubscr: Subscription;
  filtered;
  allUsers;
  showLoader: boolean = false;
  color = 'primary';
  mode = 'indeterminate';
  value = 2;
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocomplete: MatAutocompleteTrigger;
  constructor(private api: apiService) { }
  displayFn(person?): string | undefined {
    return person ? person.name : person;
  }
  
  ngOnInit() {


    this.filterSubscr = this.peoplePickerControl.valueChanges.subscribe(input => {
      this.showLoader = true;
      if (!this.usersSubscr) {
        if (input.length >= 3) {
          this.usersSubscr = this.api.getUsers().subscribe(result => {
            if (result) {
              this.allUsers = result;
              this.filterUsers(this.peoplePickerControl.value);
            }

          });
        }

      } else {
        if (this.peoplePickerControl.value.length == 3) {

          this.usersSubscr = this.api.getUsers().subscribe(item => {

            this.allUsers = item
            this.filterUsers(this.peoplePickerControl.value.toLowerCase())
          })
        }
        if (this.peoplePickerControl.value.length > 3) {

          this.filterUsers(this.peoplePickerControl.value.toLowerCase())

        }
        if (this.peoplePickerControl.value.length < 3) {
          this.showLoader = false;
          this.autocomplete.closePanel();

        }
        if (this.peoplePickerControl.value.length == 0) {

          this.userSelected.emit("")
        }
      }

    })

  }
  filterUsers(searchTerm: string) {
    if (this.allUsers) {
      this.filtered = this.allUsers.filter(option => {
        if (option.name) {
          return option.name.toLowerCase().includes(searchTerm.toLowerCase())
          } else {
          return option.name.toLowerCase().includes(searchTerm.toLowerCase())
          }
      })
      this.showLoader = false;
    }

  }
  ngOnDestroy() {
    
    if (this.filterSubscr) {
      this.filterSubscr.unsubscribe();
    }
    if (this.usersSubscr) {
      this.usersSubscr.unsubscribe();
    }
  }
}

