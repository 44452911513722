import { Component } from '@angular/core';
import { DataFlowService } from '../../services/data-flow.service';
import { Subscription } from 'rxjs';
import { CommonPresenter } from '../../services/common.presenter';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'what-where-component',
  templateUrl: './what-where.component.html',
})
export class WhatWhereComponent {
  constructor(private data: DataFlowService, private domSanitizer: DomSanitizer, private presenter: CommonPresenter) { }
  defectsInfoSubscr: Subscription;
  currentUserSubscr: Subscription;
  currentUser;
  defectsData;
  general: boolean = false;
  commonDefectData;
  sketch;
  metricSys: boolean;
  saveURL(url) {
    if (url) {
      return this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + url.image)
    }
  }
  convertValue(val) {
    if (val && val != '') {
      if (!this.metricSys) {
        return this.presenter.convertMmToInches(val)
      } else {
        return val
      }
    }

  }
  returnDimenstion(arr, id) {
    let vals = arr.filter(item => item.dimensionId == id)
    if (vals.length == 1) {
      return vals[0].value
    }
  }
  ngOnInit() {
    this.defectsInfoSubscr = this.data.castTicketInfo.subscribe(result => {
      if (result) {
        this.defectsData = result.defects;
        this.sketch = result.defects[0].sketch
        this.commonDefectData = this.defectsData[0]
       
        if (this.commonDefectData.sketch && this.commonDefectData.sketch.name == 'General') {
          this.general = true;
        }
        
      }

    })
    this.currentUserSubscr = this.data.castCurrentUser.subscribe(result => {
      if (result) {
     
        this.metricSys = result.metricSystem
       
      }
    })
  }
  ngOnDestroy() {
    this.defectsInfoSubscr.unsubscribe();
    this.currentUserSubscr.unsubscribe();
  }

}
