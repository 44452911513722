import { Component, Input, Output, EventEmitter} from '@angular/core';

/**
 * @title Basic slide-toggles
 */
@Component({
  selector: 'slide-toggle-component',
  templateUrl: 'slide-toggle-component.html',

})
export class SlideToggleComponent{
  @Input() label: string;
  @Input() checked: boolean;
  @Output() emitSlideToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  changeToggle(e) {
    if (e.checked) {
      this.label = 'yes'
     
    } else {
      this.label = 'no'
    }
    this.emitSlideToggle.emit(e.checked)
  }
  ngOnInit() {

  }
}
