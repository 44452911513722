import { Component } from '@angular/core';

/**
 * @title Basic slide-toggles
 */
@Component({
  selector: 'reports-component',
  templateUrl: 'reports.component.html'

})
export class ReportsComponent {

}
